import { lazy, Suspense } from 'react'

import { Loader } from './components/Loader'

const Providers = lazy(() =>
  import('./components/Providers').then((module) => ({
    default: module.Providers,
  })),
)

export const App = (): JSX.Element | null => {
  return (
    <Suspense fallback={<Loader />}>
      <Providers />
    </Suspense>
  )
}
